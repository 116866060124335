import "./Info.css";
const Info = () => (
  <div id="info">
    <div style={{ display: "flex", flexFlow: "row" }}>
      <div id="fax">Fax: (559) 738 - 8680</div>
      <div id="Phone">Phone: (559) 242 - 6559</div>
    </div>
    <div id="address">Address: 1836 W. Dorothea Ave, Visalia, CA 93277</div>
  </div>
);
export default Info;
