import { Header, Info } from "../components";
import Footer from "../components/Footer"
import "./Home.css"
const Home = () => {
  return (
    <div id="home">
      <div>
        <Header />
        <Motto />
        <Services />
      </div>
      <Footer />
    </div>
  )
}

const Motto = () => {
  return <div id="motto">Here to serve. Here to learn. Here to have fun.</div>;
};
const Services = () => {
  return (
    <div id="services">
      <span id="title">Our Services</span>
      <div id="info-cards">
        <InfoCard name="Tax Planning" />
        <InfoCard name="Tax Filing" />
        <InfoCard name="Bookkeeping" />
      </div>
    </div>
  );
};
const InfoCard = ({ name }) => <div className="service-card">{name}</div>;
export default Home;
