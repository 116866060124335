import { Button, IconButton, Switch, TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Header } from "../components";
import "./Admin.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { capitalizeName } from "./AboutUs";
import { Fab } from "@material-ui/core";
import { Add, CallToActionSharp, Done } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { callApi } from "../utils";
require("dotenv").config();
const newEmptyUser = () => ({
  name: "",
  username: "",
  position: "",
  bio: "",
  img: null,
  id: null,
});

const Admin = ({ users, setUsers }) => {
  const [eUser, setUser] = useState(null);
  const [token, setToken] = useState("");
  // useEffect(() => {
  //   setToken("1");
  // });
  const handleSubmit = async () => {
    try {
      console.log(eUser);
      const { bio, username, id, name, position, img } = eUser;
      if (!bio || !name || !position) {
        alert("please fill out all fields");
        return;
      }
      const fields = { bio, username, id, name, position, img };
      // console.log(fields);
      console.log("fields :>> ", fields);
      if (eUser.id) {
        await callApi({
          url: "users",
          method: "PATCH",
          token,
          body: { fields: fields },
        });
        setUsers(await callApi({ url: "users" }));
        setUser(null);
      } else {
        await callApi({
          url: "users",
          method: "POST",
          token,
          body: { bio, position, name, img },
        });
        setUsers(await callApi({ url: "users" }));
        // setUsers([...users, eUser]);
        setUser(null);
      }
      setUser(null);
    } catch (error) {
      console.error(error);
    }
    // callApi();
  };
  return (
    <>
      <Header />
      <div id="admin">
        {token ? (
          <>
            <ManageSite
              id="manage"
              users={users}
              eUser={eUser}
              setUser={setUser}
              setUsers={setUsers}
              token={token}
            />
            {/* {eUser && eUser.img && <img src={eUser.img} alt="img" />} */}
          </>
        ) : (
          <Login id="login" setToken={setToken} />
        )}
        {eUser && (
          <>
            <div id="outer">
              <span
                style={{
                  font: "20px bolder",
                  margin: "20px",
                  fontFamily:
                    "Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif",
                }}
              >
                {eUser.id === null ? "NEW EMPLOYEE" : "EDIT EMPLOYEE"}
              </span>
              <EditUser user={eUser} id="edit-user" setUser={setUser} />
            </div>
          </>
        )}
      </div>
      {token && (
        <>
          (
          {eUser ? (
            <Fab color="secondary" id="add" onClick={handleSubmit}>
              <Done />
            </Fab>
          ) : (
            <Fab
              color="primary"
              id="add"
              onClick={() => {
                setUser(newEmptyUser());
              }}
            >
              <Add />
            </Fab>
          )}
          ){" "}
        </>
      )}
    </>
  );
};

const ManageSite = ({ users, eUser, setUser, token, setUsers, ...props }) => {
  const handleDelete = async (id) => {
    try {
      console.log(id);
      await callApi({
        url: `users/${id}`,
        method: "DELETE",
        token,
        body: {},
      });
      setUsers(await callApi({ url: "users" }));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div id={props.id}>
      <span
        style={{ font: "30px bold", margin: "10px", fontFamily: "Calibri" }}
      >
        Employees
      </span>
      <div id="list">
        {users
          .filter((u) => !u.isAdmin)
          .map((u) => (
            <ListItem
              user={u}
              key={u.id}
              setUser={setUser}
              eUser={eUser}
              className="litem"
              handleDelete={handleDelete}
            />
          ))}
      </div>
    </div>
  );
};

const ListItem = ({ user, setUser, eUser, handleDelete, ...props }) => {
  return (
    <div className={props.className}>
      <span style={{ justifyContent: "flex-start", margin: "10px" }}>
        {capitalizeName(user.name)}
      </span>
      <span>
        <IconButton
          color="primary"
          onClick={() =>
            eUser ? eUser.id !== user.id && setUser(user) : setUser(user)
          }
        >
          <EditIcon />
        </IconButton>
      </span>
      <span>
        <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
          <DeleteIcon />
        </IconButton>
      </span>
    </div>
  );
};
const EditUser = ({ user, setUser, ...props }) => {
  const img = useRef();
  let imageAsDataURL = null;
  const setImg = () => {
    const PP = img.current;
    imageAsDataURL = PP.getImageAsDataUrl(1);
    setUser({ ...user, img: imageAsDataURL });
  };
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <div id={props.id}>
      <ProfilePicture ref={img} useHelper={true} minImageSize={250} />
      <Button color="primary" variant="contained" onClick={setImg}>
        confirm picture
      </Button>
      <TextField
        value={user.name}
        label="Name"
        variant="outlined"
        name="name"
        onChange={onChange}
      />
      <TextField
        value={user.position}
        label="Position"
        name="position"
        variant="outlined"
        onChange={onChange}
      />
      <TextField
        value={user.bio}
        label="Bio"
        name="bio"
        multiline
        rows={6}
        variant="outlined"
        onChange={onChange}
      />
    </div>
  );
};
const Login = (props) => {
  const [state, setState] = useState({});
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onSubmit = async () => {
    const t = await callApi({
      url: "users/login",
      method: "POST",
      body: state,
    });
    console.log(t);
    if (t && t.token) {
      props.setToken(t.token);
    } else {
      alert(t.message);
    }
  };
  return (
    <div id={props.id}>
      <span style={{ margin: "10px", fontSize: 20, fontWeight: "bold" }}>
        LOGIN
      </span>
      <TextField
        label="username"
        style={{ margin: "10px" }}
        onChange={onChange}
        name="username"
      />
      <TextField
        label="password"
        type="password"
        style={{ margin: "10px" }}
        onChange={onChange}
        name="password"
      />
      <Button
        variant="contained"
        color="primary"
        id="submit"
        onClick={onSubmit}
      >
        Submit
      </Button>
    </div>
  );
};
export default Admin;
