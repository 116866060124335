import "./Footer.css"
const Footer = (props) => {
  return (
    <div className="footer">
      ©2021 - {new Date().getFullYear()} Apollo Accountancy Solutions LLC. All
      rights reserved.
    </div>
  )
}
export default Footer
