import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { Header } from "../components";
import Footer from "../components/Footer"
import "./AboutUs.css"
const AboutUs = ({ users }) => {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Header />
        <div id="mtt">Meet the team</div>
        <UserList className="userlist" users={users} />
      </div>
      <Footer />
    </div>
  )
}

const UserList = ({ users, ...props }) => {
  return (
    <div className={props.className}>
      {users && users.length ? (
        users
          .filter((u) => !u.isAdmin)
          .map((user) => <User key={user.id} user={user} />)
      ) : (
        <CircularProgress color="secondary" style={{ margin: "50px" }} />
      )}
    </div>
  )
}

const User = ({ user }) => {
  // useEffect(() => {
  //   console.log(user.img);
  // }, []);
  return (
    <div className="user">
      <span className="name">{capitalizeName(user.name)}</span>
      <span className="pos">{user.position}</span>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {user.img && (
          <img
            src={user.img}
            alt={user.name + "'s image"}
            className="userimg"
          />
        )}
      </div>
      {/* <span className="bio">{user.bio}</span> */}
    </div>
  )
}

export const capitalizeName = (name) => {
  return name
    ? name
        .split(" ")
        .map((n) => n[0].toUpperCase() + n.slice(1))
        .join(" ")
    : name;
};
export default AboutUs;
