import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Info } from ".";
import "./Header.css";
import { useSpring, animated } from "react-spring";
const DropDown = ({ name, id, to, nameId, ...props }) => {
  const [active, setActive] = useState(false);
  const appear = useSpring({
    transform: active ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
    opacity: active ? 1 : 0,
    display: active ? "flex" : "none"
})
  return (
    <div
      id={id}
      onMouseLeave={() => setActive(false)}
      style={{ display: "flex", flexFlow: "column", alignItems: "center" }}
    >
      {to ? (
        <NavLink
          style={{ margin: "0px", padding: "10px" }}
          onMouseOver={() => setActive(true)}
          to={to}
        >
          {name}
        </NavLink>
      ) : (
        <span
          onMouseOver={() => setActive(true)}
          style={{ cursor: "default", padding: "10px", margin: "0px", zIndex:10, background:"white" }}
          id={nameId}
          
        >
          {name}
        </span>
      )}
      <animated.div
        id="dropdown"
        style={appear}
        onMouseLeave={() => setActive(false)}
      >
        {props.children}
      </animated.div>
    </div>
  );
};

const Header = () => {
  return (
    <>
      <Info />
      <nav id="header">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/logo.png" alt="logo" id="logoimg" />
          <span id="logo">Apollo Accountancy Solutions</span>
        </div>
        <div id="links">
          <NavLink style={{ margin: "20px" }} to="/">
            Home
          </NavLink>
          <NavLink style={{ margin: "20px" }} to="/about-us">
            About Us
          </NavLink>
          <DropDown name="Resources">
            <a href="https://link.intuit.com/login/#/signin" target="_blank">
              Intuit
            </a>
            <a href="https://www.docusign.com/user" target="_blank">
              DocuSign
            </a>
            <a
              href="https://directpay.irs.gov/directpay/payment?execution=e1s1"
              target="_blank"
            >
              IRS Direct Pay
            </a>
          </DropDown>
          <DropDown name="Contact Us">
            <a href="mailto:info@apoll0.com">Email Us</a>
            <a
              href="https://calendar.google.com/calendar/selfsched?sstoken=UU5KNkx3MzFzV3QxfGRlZmF1bHR8ZGRmZjE0Y2Q2OTUxNzQ3M2NmNDk3ZGMxNzA4ZWYyMmQ "
              target="_blank"
            >
              Siro's Calendar
            </a>
          </DropDown>
        </div>
      </nav>
    </>
  );
};
export default Header;
