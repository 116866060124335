import React from "react";
import { default as App } from "./App";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
