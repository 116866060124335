import { Home, Contact, AboutUs, Admin } from "./pages";
import { Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { callApi } from "./utils";
const App = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    async function getAboutUs() {
      try {
        const data = await callApi({ url: "users" });
        if (data) {
          console.log(data);
          setUsers(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getAboutUs();
  }, []);
  return (
    <>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/about-us">
        <AboutUs users={users} />
      </Route>
      <Route path="/admin">
        <Admin users={users} setUsers={setUsers} />
      </Route>
    </>
  );
};
export default App;
